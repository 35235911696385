// npm
import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, BoxProps, Stack, styled } from '@mui/material'
import SVG from 'react-inlinesvg'
import Marquee from 'react-fast-marquee'

export interface SectionLeadTextProps extends BoxProps {
  bgColor: string
}

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
}))

const StyledSVG = styled(SVG)(({ theme }) => ({
  opacity: 0.6,
  width: '160px',
  whiteSpace: 'nowrap',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  path: {
    fill: theme.palette.text.primary,
  },
  rect: {
    fill: theme.palette.text.primary,
  },
  polygon: {
    fill: theme.palette.text.primary,
  },
  [theme.breakpoints.up('lg')]: {
    opacity: 0.4,
  },
}))

const SectionClientLogoSlider = ({
  bgColor,
  ...props
}: SectionLeadTextProps) => {
  const data = useStaticQuery<Queries.SectionClientLogoSliderQuery>(graphql`
    query SectionClientLogoSlider {
      allDatoCmsClient(sort: { fields: position, order: ASC }) {
        edges {
          node {
            name
            originalId
            slug
            svgLogo {
              url
            }
          }
        }
      }
    }
  `)
  const clients = data.allDatoCmsClient.edges
  return (
    <Wrapper
      component="section"
      overflow="hidden"
      bgcolor={`${bgColor}`}
      {...props}
      pb={8}
    >
      <Marquee>
        {clients.map((item: any, index: number) => {
          const client: Queries.DatoCmsClient = item.node
          return (
            <StyledSVG
              key={`${client.originalId}-${index}`}
              src={client.svgLogo.url}
            />
          )
        })}
      </Marquee>
    </Wrapper>
  )
}

export default memo(SectionClientLogoSlider)
